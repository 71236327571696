import React from 'react';
import GlobalSearch from '../../platformui/Search/globalsearch';
import RegionLogo from '../header/region-logo';

const HeaderTools = (props) => {
  return (
    <ul className="c-header-tools">
      <li>
        <RegionLogo
          {...props}
          ButtonSVG={
            <i className="c-header-tools__icon c-header-tools__icon--region"></i>
          }
        />
      </li>
      <li>
        <GlobalSearch
          {...props}
          hasQuickSearch={true}
          ButtonSVG={
            <i className="c-header-tools__icon c-header-tools__icon--search"></i>
          }
        />
      </li>
    </ul>
  );
};

export default HeaderTools;
