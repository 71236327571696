import React from 'react';
const Logo = (props) => {
  return (
    <a href="/">
      <span className="c-header__site-title">
        Nationellt kliniskt kunskapsstöd
      </span>
      <span className="show-for-sr" data-cy="ui-header-logosr">{props.logosr}</span>
    </a>
  );
};

export default Logo;
