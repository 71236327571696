import React from 'react';
import classNames from 'classnames';
import PlatformHeader from '../../platformui/Header';
import Regions from './regions';
import Logo from '../logo';
import RegionsContainer from './regions-container';
import HeaderContainer from './header-container';
import { useSweLang } from '../../platformui/Helpers';

const Header = (props) => {
  return (
    <div
      className={classNames({
        header: true,
        'header--has-region': props.regionLogo || props.currentRegion,
        'header--search-is-open': props.activeTool === 'search',
      })}
      lang={useSweLang(props.Language)}
    >
      <Regions {...props} className="regions-outer-wrapper" />

      <PlatformHeader
        {...props}
        Logo={Logo}
      />
    </div>
  );
};

export default HeaderContainer(RegionsContainer(Header));
