import React from 'react';
import classNames from 'classnames';
const Burger = (props) => {
  const burgerClasses = classNames({
    'lines-button': true,
    x: true,
    close: props.mobileNavVis,
  });

  return (
    <button
      onClick={(e) => props.handleHamburgerClick(e)}
      type="button"
      className={props.burgerBtnClassname}
      aria-controls="mobile-nav"
      aria-label={
        props.Content.Translations && props.Content.Translations.menusr
      }
      aria-expanded={props.mobileNavVis}
      data-cy="ui-burger-btn"
    >
      {props.text && (
        <span className="vardpersonal-header__burger__text">{props.text}</span>
      )}

      <span className={burgerClasses}>
        <span className="vardpersonal-lines"></span>
      </span>
      <span
        className={classNames({
          'show-for-sr': !props.showHeader,
        })}
      >
        {props.Content.Translations && props.Content.Translations.menusr}
      </span>
    </button>
  );
};

export default Burger;
