import React from 'react';
import Topnav from '../../platformui/Topnav';

const TopnavVardpersonal = ({
  Children,
  LoadBlocks,
  isFullWidth,
  meganavHasHeading,
  complimentnavHeading,
  closeTranslation,
}) => {
  return (
    <Topnav
      Children={Children}
      LoadBlocks={LoadBlocks}
      isWideNav={true}
      isFullWidth={isFullWidth}
      meganavHasHeading={meganavHasHeading}
      complimentnavHeading={complimentnavHeading}
      closeTranslation={closeTranslation}
      meganavClassName="vardpersonal-meganav"
    />
  );
};

export default TopnavVardpersonal;
