import React from 'react';

export default () => (
  <svg
    className="region-logo__img region-logo__img--is-visible"
    xmlns="http://www.w3.org/2000/svg"
    width="201"
    height="44"
    viewBox="0 0 201 44"
    preserveAspectRatio="xMinYMid meet"
  >
    <path
      d="M36.1666 24.7963C36.3126 24.6612 36.5116 24.5108 36.7439 24.3352C38.0412 23.3548 40.3805 21.5867 40.3805 17.2813C40.3805 15.8793 39.4348 11.2574 34.606 9.80174C33.729 9.53718 32.8875 9.42746 32.1186 9.41093C31.0671 9.38956 30.1499 9.54514 29.4567 9.72594C29.0574 9.8302 28.6638 9.94218 28.2788 10.0517C27.0722 10.395 25.9495 10.7144 24.9974 10.6976C23.4242 10.67 22.4477 9.8845 20.5492 7.16028C19.7772 5.87043 17.3798 1.97553 13.6387 0.539676C12.54 0.118052 11.325 -0.0919526 10.0011 0.0383992C8.50141 0.186024 7.78926 0.800877 7.3567 1.39933C7.05276 1.81921 6.9613 2.30558 7.11911 2.41058C7.1964 2.46339 7.32564 2.44512 7.48109 2.42313C8.11023 2.28408 9.3428 2.29365 9.91677 2.38361C13.4107 2.92913 15.6139 6.06615 16.3527 7.4697C17.3963 9.44958 19.5608 12.5844 21.0425 13.5616C23.8541 15.4156 26.7636 14.7819 28.1803 14.4734C28.2939 14.4487 28.3978 14.426 28.4913 14.4069C28.8496 14.334 29.1981 14.2376 29.5486 14.1407C30.3959 13.9064 31.2548 13.669 32.2907 13.7511C34.1766 13.9018 35.3227 15.2856 35.6803 16.0979C36.8394 18.7236 35.1238 20.2247 34.5919 20.69C34.5438 20.7321 34.5054 20.7657 34.4797 20.7909C34.2362 21.0295 34.0459 21.1815 33.8502 21.338C33.6728 21.4797 33.491 21.6251 33.2609 21.8416C31.4673 23.5301 30.7372 25.3141 31.3844 27.796C31.6359 28.4918 31.99 29.4014 32.3209 30.2514C32.7338 31.312 33.1106 32.2799 33.2072 32.6247C33.944 35.2558 33.7418 36.9072 33.2796 37.9765C32.702 39.3123 31.7598 39.7389 31.3954 39.9039C31.3535 39.9228 31.3192 39.9384 31.294 39.9514C30.9649 40.1218 31.4171 40.4826 32.2165 40.4825C33.459 40.4823 34.7111 39.8021 35.5825 38.4829C36.5804 36.9736 37.0429 34.6282 36.2567 31.5101C36.0022 30.5524 35.7543 29.7914 35.546 29.1518C35.1644 27.9802 34.9155 27.2161 35.0022 26.3981C35.0758 25.7132 35.594 25.28 35.9647 24.9702C36.0396 24.9076 36.1086 24.8499 36.1666 24.7963Z"
      fill="#353535"
    />
    <path
      d="M29.7441 42.5475C29.7134 42.4003 29.4535 42.3143 28.9842 42.159C28.887 42.1269 28.7808 42.0917 28.6658 42.0524L28.6611 42.0517L28.6618 42.0508C27.342 41.5961 25.9606 40.7618 25.2319 39.0219C24.7134 37.8482 24.9565 36.7226 25.2543 35.3441C25.3849 34.7395 25.526 34.0862 25.618 33.3589C25.6788 32.8812 25.7972 30.9874 24.9501 29.4442C23.9903 27.6932 21.9212 25.9393 17.9481 26.5408C17.8938 26.549 17.8331 26.5595 17.7669 26.5709C17.0876 26.6883 15.8238 26.9066 14.8118 25.8011C14.0816 25.0024 13.9784 22.9832 13.8821 21.098C13.8516 20.5014 13.8218 19.918 13.773 19.3911C13.4244 15.6099 12.1239 13.8038 11.6288 13.1162C11.607 13.0859 11.5867 13.0578 11.5682 13.0318C10.4876 11.5145 8.9964 10.6667 7.05375 9.56223C6.70204 9.36228 6.33554 9.15391 5.95399 8.93163C3.05693 7.24551 3.19188 4.38075 3.65997 2.95197C3.83233 2.42492 4.13173 1.86084 4.33604 1.47593L4.34914 1.45125C4.41428 1.32847 4.4936 1.20251 4.56828 1.08392C4.7543 0.788522 4.91156 0.538795 4.74939 0.497654C4.25905 0.373764 3.11695 1.29343 2.57069 1.92966C2.4719 2.04485 2.37323 2.16489 2.2778 2.29276C1.37627 3.50606 -0.705384 8.0767 3.7057 11.6504C3.92106 11.8247 4.25666 12.0169 4.65578 12.2454C5.76373 12.8798 7.36116 13.7944 8.23459 15.3857C8.75003 16.1964 9.15265 17.105 9.34998 18.1391C9.56876 19.2813 9.62857 21.1602 9.66879 22.4236C9.68588 22.9606 9.69944 23.3864 9.72016 23.5971C9.7936 24.3059 9.9104 25.0575 10.1307 25.8531C11.0156 29.0487 13.5251 30.727 16.0781 30.9006C16.8159 30.9507 18.0621 30.8535 18.9507 30.7843C19.4226 30.7475 19.7938 30.7186 19.9342 30.7237C20.7158 30.7167 21.5004 31.1383 21.7681 32.4392C21.9639 33.3938 21.733 34.9036 21.6841 35.224C21.6795 35.2536 21.6766 35.273 21.6756 35.281C21.4254 37.2932 21.7752 39.7522 24.0763 41.6878C24.0825 41.6926 24.0914 41.6996 24.1028 41.7087C24.3734 41.9231 26.0831 43.2777 28.6935 43.0361C29.5049 42.9485 29.767 42.658 29.7441 42.5475Z"
      fill="#353535"
    />
    <path
      d="M89.548 15.1289C88.606 15.1289 87.8022 15.8867 87.8022 16.8516C87.8022 17.8161 88.606 18.5972 89.548 18.5972C90.5127 18.5972 91.2937 17.8161 91.2937 16.8516C91.2937 15.8867 90.5127 15.1289 89.548 15.1289Z"
      fill="#353535"
    />
    <path
      d="M91.0179 30.8862H88.1007V19.7917H91.0179V30.8862Z"
      fill="#353535"
    />
    <path
      d="M57.3552 30.9117L54.3459 25.4679H51.9571V30.9117H48.6953V15.5908H55.8622C59.0549 15.5908 61.0073 17.6809 61.0073 20.5293C61.0073 23.2397 59.3075 24.6869 57.6538 25.0773L61.0992 30.9117H57.3552ZM57.6767 20.5292C57.6767 19.2428 56.689 18.4619 55.3797 18.4619H51.957V22.5963H55.3797C56.689 22.5963 57.6767 21.8155 57.6767 20.5292Z"
      fill="#353535"
    />
    <path
      d="M67.6378 19.541C64.2611 19.541 61.8955 22.1367 61.8955 25.3525C61.8955 28.9128 64.445 31.1869 67.8217 31.1869C69.5444 31.1869 71.2902 30.6588 72.3925 29.6481L71.1064 27.7644C70.3943 28.4535 69.0849 28.8671 68.1432 28.8671C66.2595 28.8671 65.134 27.6956 64.9505 26.2943H73.1965V25.6511C73.1965 22.0219 70.9457 19.541 67.6378 19.541ZM64.9046 24.3188C65.0423 23.2161 65.8234 21.8609 67.638 21.8609C69.5673 21.8609 70.3026 23.2621 70.3714 24.3188H64.9046Z"
      fill="#353535"
    />
    <path
      d="M76.3032 31.6221L74.994 33.735C76.3952 34.9525 77.8652 35.389 79.7718 35.389C82.5052 35.389 85.767 34.3555 85.767 30.1288V19.7923H82.8268V21.2164C81.9308 20.0909 80.7365 19.5166 79.4043 19.5166C76.6018 19.5166 74.5117 21.538 74.5117 25.1672C74.5117 28.8654 76.6248 30.8178 79.4043 30.8178C80.7595 30.8178 81.9538 30.1749 82.8268 29.0722V30.1978C82.8268 32.3798 81.1728 33.0001 79.7718 33.0001C78.3706 33.0001 77.199 32.6096 76.3032 31.6221ZM82.8268 23.3757V26.9588C82.3445 27.671 81.2876 28.2221 80.323 28.2221C78.6691 28.2221 77.5206 27.0737 77.5206 25.1671C77.5206 23.2607 78.6691 22.1121 80.323 22.1121C81.2876 22.1121 82.3445 22.6405 82.8268 23.3757Z"
      fill="#353535"
    />
    <path
      d="M92.8696 25.3281C92.8696 22.1812 95.0748 19.5166 98.7271 19.5166C102.402 19.5166 104.607 22.1812 104.607 25.3281C104.607 28.498 102.402 31.1624 98.7271 31.1624C95.0748 31.1624 92.8696 28.498 92.8696 25.3281ZM101.575 25.328C101.575 23.6052 100.542 22.1121 98.7272 22.1121C96.9125 22.1121 95.9019 23.6052 95.9019 25.328C95.9019 27.0735 96.9125 28.5667 98.7272 28.5667C100.542 28.5667 101.575 27.0735 101.575 25.328Z"
      fill="#353535"
    />
    <path
      d="M113.943 30.8879V24.1807C113.943 22.6415 113.139 22.1132 111.899 22.1132C110.75 22.1132 109.855 22.7564 109.372 23.4225V30.8879H106.455V19.7933H109.372V21.2405C110.084 20.3904 111.44 19.5176 113.254 19.5176C115.689 19.5176 116.86 20.8959 116.86 23.0551V30.8879H113.943Z"
      fill="#353535"
    />
    <path
      d="M135.056 30.8874V24.4557H127.82V30.8874H124.559V15.5664H127.82V21.5846H135.056V15.5664H138.342V30.8874H135.056Z"
      fill="#353535"
    />
    <path
      d="M148.53 30.8869V29.4626C147.658 30.5653 146.44 31.1624 145.108 31.1624C142.306 31.1624 140.192 29.0493 140.192 25.351C140.192 21.7218 142.284 19.5166 145.108 19.5166C146.418 19.5166 147.634 20.0911 148.508 21.2164V19.7923H151.472V30.8869H148.53ZM148.531 27.3042V23.3992C148.049 22.6642 146.992 22.136 146.004 22.136C144.351 22.136 143.202 23.4222 143.202 25.3516C143.202 27.2582 144.351 28.5674 146.004 28.5674C146.992 28.5674 148.049 28.0392 148.531 27.3042Z"
      fill="#353535"
    />
    <path d="M153.803 30.8874H156.72V15.5664H153.803V30.8874Z" fill="#353535" />
    <path
      d="M159.099 30.8874H162.016V15.5664H159.099V30.8874Z"
      fill="#353535"
    />
    <path
      d="M172.203 30.8869V29.4626C171.331 30.5653 170.113 31.1624 168.781 31.1624C165.978 31.1624 163.865 29.0493 163.865 25.351C163.865 21.7218 165.955 19.5166 168.781 19.5166C170.09 19.5166 171.307 20.0911 172.18 21.2164V19.7923H175.143V30.8869H172.203ZM172.203 27.3042V23.3992C171.721 22.6642 170.664 22.136 169.677 22.136C168.023 22.136 166.874 23.4222 166.874 25.3516C166.874 27.2582 168.023 28.5674 169.677 28.5674C170.664 28.5674 171.721 28.0392 172.203 27.3042Z"
      fill="#353535"
    />
    <path
      d="M184.962 30.8879V24.1807C184.962 22.6415 184.158 22.1132 182.917 22.1132C181.768 22.1132 180.872 22.7564 180.39 23.4225V30.8879H177.473V19.7933H180.39V21.2405C181.102 20.3904 182.458 19.5176 184.272 19.5176C186.707 19.5176 187.878 20.8959 187.878 23.0551V30.8879H184.962Z"
      fill="#353535"
    />
    <path
      d="M198.06 30.8864V29.4621C197.186 30.5648 195.97 31.1619 194.638 31.1619C191.834 31.1619 189.722 29.0488 189.722 25.3505C189.722 21.7213 191.812 19.5161 194.638 19.5161C195.946 19.5161 197.186 20.0906 198.06 21.2159V15.5654H201V30.8864H198.06ZM198.06 27.3029V23.375C197.578 22.6399 196.52 22.1115 195.532 22.1115C193.878 22.1115 192.73 23.4209 192.73 25.3503C192.73 27.2569 193.878 28.5661 195.532 28.5661C196.52 28.5661 197.578 28.0379 198.06 27.3029Z"
      fill="#353535"
    />
  </svg>
);
