import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { isExpanded } from './helpers';

const RegionLogo = (props) => {
  const [expanded, setExpanded] = useState(isExpanded(props));

  useEffect(() => {
    if (props.activeTool === 'region-picker') {
      setExpanded(!expanded);
    }

    if (props.activeTool !== 'region-picker') {
      setExpanded(false);
    }
  }, [props.activeTool]);

  const { CurrentRegionLogo } = props;

  return (
    <div className="region-logo">
      <div className="header-tools__regions">
        {props.Content.Translations && (
          <div className="header-tools__regions__print-label">
            <p>{props.Content.Translations.label}</p>
          </div>
        )}
        <button
          className="header-tools__regions__button"
          type="button"
          id="region-picker"
          onClick={() => props.toggle('region-picker')}
          aria-expanded={props.isVisible}
        >
          {props.currentRegion && (
            <span className="show-for-sr">
              Du har valt region {props.currentRegion.Label}.
            </span>
          )}
          <span
            className={classNames({
              'header-tools__regions-picker': true,
              'header-tools__regions-picker--is-expanded': expanded,
              'header-tools__regions-picker--no-region':
                !props.regionLogo && !props.currentRegion,
            })}
          >
            <span className={classNames({ 'header-tools__icon-container': true, 'header-tools__icon-container--hide-icon': props.currentRegion })}>
              <i className="header-tools__icon" />
            </span>
            {!props.currentRegion ? (
              <span>
                Välj region
                {props.currentRegion && (
                  <span className="show-for-sr">Välj en annan region</span>
                )}
              </span>
            ) : (
              <div className="region-logo__container">
                {' '}
                <CurrentRegionLogo {...props} />
              </div>
            )}
          </span>
        </button>
      </div>
    </div>
  );
};

export default RegionLogo;
