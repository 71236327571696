import React from 'react';
import { withRouter } from 'react-router';
import classNames from 'classnames';
import withKeepTabFocus from '../../platformui/Containers/withKeepTabFocus';

const Regions = (props) => {
  if (!props.Regionalization || typeof window === 'undefined') return null;
  const { Options } = props.Regionalization.Regions;
  const { DefaultOption } = props.Regionalization.Regions;

  const regionPicker = classNames({
    'region-picker': true,
    'region-picker--is-expanded': props.isVisible,
  });

  const selectedProfession = props.Content?.Switch?.CurrentSelectionCodeParam;

  const uuid = props.Content?.Publication?.currentUuidParam;

  return (
    <div
      className={regionPicker}
      aria-hidden={!props.isVisible}
      id="region-wrapper"
    >
      <div className="row expanded regions">
        <div className="regions__wrapper">
          <header className="regions__header">
            {props.isVisible ? (
              <h1 className="regions__heading">
                {/* span element with id and tabindex to set focus due to accessibility */}
                <span
                  id="region-wrapper-focus"
                  className="region-picker__focus"
                  tabIndex="-1"
                >
                  {props.Regionalization.Heading}
                </span>
              </h1>
            ) : (
              <h1 className="regions__heading">
                {props.Regionalization.Heading}
              </h1>
            )}
          </header>

          <div className="regions__container-wrapper">
            <div className="regions__container">
              {Options && (
                <ul className="regions__list">
                  {Options.map(({ Value, Label, Selected }, index) => (
                    <li className="regions__listitem" key={index}>
                      <a
                        href={
                          props.IsEditMode
                            ? `${window.location.pathname}?globalregion=${Value}`
                            : `${window.location.pathname}${
                                uuid ? '?' + uuid : ''
                              }${
                                uuid && selectedProfession
                                  ? '&' + selectedProfession
                                  : ''
                              }${
                                !uuid && selectedProfession
                                  ? '?' + selectedProfession + ''
                                  : ''
                              }${!uuid && !selectedProfession ? '?' : ''}${
                                uuid || selectedProfession ? '&' : ''
                              }globalregion=${Value}`
                        }
                        rel="nofollow"
                        className="regions__listitem__button"
                      >
                        {Label}
                        <span
                          className={classNames({
                            regions__listitem__icon: true,
                            'selected-region': Selected,
                          })}
                          aria-hidden={true}
                        />
                      </a>
                    </li>
                  ))}
                </ul>
              )}

              <a
                href={
                  props.IsEditMode
                    ? `${window.location.pathname}?globalregion=00`
                    : `${window.location.pathname}${uuid ? '?' + uuid : ''}${
                        uuid && selectedProfession
                          ? '&' + selectedProfession
                          : ''
                      }${
                        !uuid && selectedProfession
                          ? '?' + selectedProfession + ''
                          : ''
                      }${!uuid && !selectedProfession ? '?' : ''}${
                        uuid || selectedProfession ? '&' : ''
                      }globalregion=00`
                }
                rel="nofollow"
                className={classNames({
                  regions__listitem__button: true,
                  'no-border': true,
                })}
              >
                {DefaultOption.Label}
                <span
                  className={classNames({
                    regions__listitem__icon: true,
                    'selected-region': DefaultOption.Selected,
                  })}
                  aria-hidden={true}
                />
              </a>
              <div
                className="regions__footer"
                dangerouslySetInnerHTML={{ __html: props.Regionalization.Text }}
              />
            </div>
          </div>
        </div>
        <div className="regions__close">
          <button
            className="regions__close-btn"
            type="button"
            onClick={() => {
              document.getElementById('region-picker').focus();
              props.toggle('region-picker');
            }}
            aria-expanded={props.isVisible}
          >
            <span className="show-for-sr">Stäng regionsväljaren</span>
            <span className="regions__close-btn-text">Stäng</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default withRouter(withKeepTabFocus(Regions));
