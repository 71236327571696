import React, { useState, useEffect } from 'react';
import AlertList from '../../platformui/AlertList';
import classNames from 'classnames';
import GlobalSearch from '../globalsearch-vardpersonal';
import HeaderContainer from './header-container';
import HeaderTools from '../header-tools-vardpersonal';
import Logo from '../logo-vardpersonal';
import Mobile from '../../platformui/Mobilenav';
import RegionLogo from '../header-vardpersonal/region-logo';
import Regions from './regions';
import RegionsContainer from './regions-container';
import TopnavVardpersonal from '../topnav-vardpersonal';
import { useSweLang } from '../../platformui/Helpers';

const Header = (props) => {
  const [mobileNavVisible, setMobileNavVisible] = useState(false);
  const [animationStart, setAnimationStart] = useState(false);
  const [menuItems, setMenuItems] = useState([]);

  const {
    LangSelectorSmall,
    isToolsVisible,
    BuildInfo,
    height,
    activeTool,
    regionLogo,
    currentRegion,
    Language,
    isFullWidth,
    MainNavigation,
    LoadBlocks,
    complimentnavHeading,
    mobileNavHasFooterLinks,
    SiteFooter,
    PageId,
    renderAfterMobileMenu,
    isFullOverlayInMobile,
    chevronMobile,
    SwitchToResidentSettings,
  } = props;

  const { NavigateToLinkUrl, SwitchButtonText, SwitchButtonTextMobile } =
    SwitchToResidentSettings;

  let timerMobileNav = null;

  const handleClick = (e) => {
    e.preventDefault();
    setMobileNavVisible(!mobileNavVisible);
    clearTimeout(timerMobileNav);
    timerMobileNav = setTimeout(() => {
      setAnimationStart(!animationStart);
    }, 5);
  };

  const addNewMenuItem = () => {
    if (NavigateToLinkUrl && SwitchButtonTextMobile) {
      const switchMenuItemText = (
        <span className="switch-icon--mobile__container">
          <i className="switch-icon--mobile"></i>
          <span className="switch-icon__link">{SwitchButtonTextMobile}</span>
        </span>
      );

      const newMenuItem = {
        Text: switchMenuItemText,
        Url: NavigateToLinkUrl?.Uri,
        HideChildrenInMobileMenu: false,
        IsLeafNode: true,
      };

      setMenuItems((prevNavigation) => [...prevNavigation, newMenuItem]);
    }
  };

  useEffect(() => {
    if (props.isFullOverlayInMobile) {
      !mobileNavVisible && document.body.removeAttribute('class');
    }
  });

  useEffect(() => {
    if (MainNavigation && MainNavigation.length > 0) {
      setMenuItems(MainNavigation);
    }
  }, [MainNavigation]);

  useEffect(() => {
    addNewMenuItem();
  }, []);

  const headerClasses = classNames({
    'vardpersonal-header': true,
    'vardpersonal-header--is-tools-visible': isToolsVisible,
    'c-header--is-navigation': mobileNavVisible,
    'c-header--is-animating': animationStart,
    'vardpersonal-header--showbuildinfo': BuildInfo,
  });

  const headerNavClasses = classNames({
    'vardpersonal-header__navigation': true,
  });

  const checkViewboxIsMobile = () => {
    if (typeof window === 'undefined') {
      return false;
    }
    const mq = window.matchMedia('(min-width: 1025px)');
    mq.matches ? false : true;
  };

  return (
    <>
      <div
        className={classNames({
          header: true,
          'header--has-region': regionLogo || currentRegion,
          'header--search-is-open': activeTool === 'search',
        })}
        lang={useSweLang(Language)}
      >
        <GlobalSearch
          isMobile={checkViewboxIsMobile()}
          hasQuickSearch
          {...props}
          id="globalsearch"
        />
        <Regions className="regions-outer-wrapper" {...props} />
      </div>
      <header>
        <div className={headerClasses}>
          <div
            className="vardpersonal-header__top"
            style={{ paddingTop: height }}
          >
            <div className="vardpersonal-header__top__inner">
              <div className="vardpersonal-header__main">
                <div className="header-tools__container">
                  <div className="vardpersonal-header__logo">
                    <Logo
                      logoSr="Till startsidan"
                      link="/"
                      width="100"
                      height="40"
                      fill="#396291"
                    />
                    <span className="vardpersonal-header__logo__span">
                      för vårdpersonal
                    </span>
                    <span className="vardpersonal-header__logo__span vardpersonal-header__logo__span--is-desktop">
                      <RegionLogo {...props} />
                    </span>
                  </div>
                  <HeaderTools
                    {...props}
                    mobileNavVis={mobileNavVisible}
                    animationStart={animationStart}
                    switchToResidentUrl={NavigateToLinkUrl?.Uri}
                    switchToResidentText={SwitchButtonText}
                    handleHamburgerClick={(e) => handleClick(e)}
                    checkViewboxIsMobile={checkViewboxIsMobile()}
                  />
                </div>
                {checkViewboxIsMobile && (
                  <button
                    type="button"
                    aria-controls="panel-globalsearch"
                    className="vardpersonal-header__search-mobile"
                    data-cy="vardpersonal-header-search-btn"
                    onClick={() => {
                      props.toggle('search');
                    }}
                    aria-expanded={activeTool === 'search'}
                  >
                    <i
                      className={
                        props.activeTool === 'search'
                          ? 'search-icon search-icon--close'
                          : 'search-icon search-icon--open'
                      }
                    />
                    <span className="show-for-sr">
                      {props.Content.Translations.quicklinkssr}
                    </span>
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className={headerNavClasses} data-cy="header-navigation">
            <nav
              className="c-mobilenav-wrapper"
              id="mobile-nav"
              aria-hidden={!mobileNavVisible}
              data-cy="ui-mobile-nav"
            >
              <div className="c-mobilenav">
                <Mobile
                  ComponentClassName="vardpersonal-mobile"
                  isFullOverlayInMobile={isFullOverlayInMobile}
                  mobileNavHasFooterLinks={
                    mobileNavHasFooterLinks && SiteFooter
                  }
                  Children={menuItems}
                  PrefixId="mobile"
                  CurrentPageId={PageId}
                  ExpandBtnText={
                    props.Content.Translations &&
                    props.Content.Translations.expandbuttontext
                  }
                  chevronMobile={chevronMobile}
                />
              </div>
              {renderAfterMobileMenu(mobileNavVisible)}
            </nav>
            <div className="row">
              <div className="columns topnav-vardpersonal">
                <TopnavVardpersonal
                  Children={MainNavigation}
                  LoadBlocks={LoadBlocks}
                  isFullWidth={isFullWidth}
                  complimentnavHeading={complimentnavHeading}
                  closeTranslation={props.Content.Translations.close}
                />
              </div>
            </div>
          </div>
          {LangSelectorSmall && (
            <div className="row hide-for-large">
              <div className="medium-8 medium-centered">
                <LangSelectorSmall />
              </div>
            </div>
          )}
        </div>
        <AlertList />
      </header>
    </>
  );
};

Header.defaultProps = {
  renderAfterMobileMenu: () => {},
};

export default HeaderContainer(RegionsContainer(Header));
