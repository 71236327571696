import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { isExpanded } from './helpers';
import { isMediumViewport } from '../../platformui/Hooks/useMedia';

const RegionLogo = (props) => {
  const [expanded, setExpanded] = useState(isExpanded(props));

  useEffect(() => {
    if (props.activeTool === 'region-picker') {
      setExpanded(!expanded);
    }

    if (props.activeTool !== 'region-picker') {
      setExpanded(false);
    }
  }, [props.activeTool]);

  return (
    <div className="region-logo">
      <div className="c-header-tools__regions">
        {props.Content.Translations && (
          <div className="c-header-tools__regions__print-label">
            <p>{props.Content.Translations.label}</p>
          </div>
        )}
        <button
          className="c-header-tools__regions__button"
          type="button"
          id="region-picker"
          onClick={() => props.toggle('region-picker')}
          aria-expanded={props.isVisible}
        >
          {props.ButtonSVG}
          {props.currentRegion && !isMediumViewport() && (
            <>
              <span className="show-for-sr">Du har valt region</span>
              <span className="c-header-tools__regions__current-region">
                {props.currentRegion.Label}
              </span>
            </>
          )}
          <span
            className={classNames({
              'c-header-tools__regions-picker': true,
              'c-header-tools__regions-picker--is-expanded': expanded,
              'c-header-tools__regions-picker--no-region':
                !props.regionLogo && !props.currentRegion,
            })}
          >
            {props.currentRegion && (
              <span className="show-for-sr">Välj en annan region</span>
            )}{' '}
          </span>
        </button>
      </div>
    </div>
  );
};

export default RegionLogo;
