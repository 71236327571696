import React from 'react';

const FooterLogo = (props) => {
  return (
    <>
      {props.logotext && props.link ? (
        <span className="c-footer__logo__title">
          <a href={props.link}>{props.logotext}</a>
        </span>
      ) : (
        props.logotext && (
          <span className="c-footer__logo__title">{props.logotext}</span>
        )
      )}
    </>
  );
};

export default FooterLogo;
