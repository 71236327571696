import React, { useState } from 'react';
import FooterVardpersonal from '../../components/footer-vardpersonal';
import FooterLogo from '../../components/footer/logo';
import HeaderVardpersonal from '../../components/header-vardpersonal';
import InformationModal from './../../components/information-modal';
import AccessibilityNav from '../../platformui/AccessibilityNav';
import * as Render from '../../platformui/Base/Render';
import CookiePanel from '../../platformui/CookiePanel';
import Footer from '../../platformui/Footer';
import Header from '../../components/header';
import HeaderTools from './../../components/header-tools/index';
import { isMediumViewport } from '../../platformui/Hooks/useMedia';
import ScrollToTop from '../../platformui/ScrollToTop';
import SurveyEntry from '../../platformui/SurveyEntry';

const AccessibilityShortcuts = (props) => {
  return (
    <AccessibilityNav>
      <li>
        <a href="#content">Till sidans huvudinnehåll</a>
      </li>
      {props.PageTypeName === 'SearchPage' && (
        <li>
          <a href="#search">Till sökresultatet</a>
        </li>
      )}
    </AccessibilityNav>
  );
};

const Layout = (props) => {
  const [isModalActive, setIsModalActive] = useState(false);

  const footerProps = {
    ...props,
    SiteFooter: {
      ...props.SiteFooter,
      LogoText: props.SiteFooter.Heading,
    },
  };

  const activeSurvey = props.SurveyEntries?.find((survey) => survey.IsActive);
  return (
    <>
      {props.BuildInfo && <props.BuildInfoComponent {...props} />}
      {props.InformationModal && props.InformationModal.IsEnabled && (
        <InformationModal active={setIsModalActive} {...props} />
      )}
      <div {...(isModalActive ? { inert: 'true' } : {})}>
        <AccessibilityShortcuts {...props} />
        {props.FeatureToggleSettings?.Use1177ProfessionDesign ? (
          <HeaderVardpersonal
            key="header"
            HasSearch={true}
            topMenuSearch={true}
            activeTool={props.activeTool}
            isFullWidth={true}
            switchToResident={props.SwitchToResidentSettings}
            hideHamburgerMenuInHeader={true}
            isFullOverlayInMobile={true}
            {...props}
          />
        ) : (
          <Header
            {...props}
            meganavHasHeading={true}
            isFullWidth={true}
            isFullOverlayInMobile={true}
            mobileNavHasFooterLinks={true}
            key="header"
            Tools={HeaderTools}
            HasSearch={true}
          />
        )}
        <div className="main-wrapper">
          {activeSurvey && <SurveyEntry {...activeSurvey} />}
          <props.PageTypeRenderer key="asyncpages" {...props} />
          <ScrollToTop key="scrolltop" {...props} />
        </div>
        {props.FeatureToggleSettings?.Use1177ProfessionDesign ? (
          <FooterVardpersonal
            {...footerProps}
            key="footer"
            siteFooterLinkListModifiers={{
              'c-linklist--no-icons': true,
            }}
            Modifiers={{ 'footer-linklist': true, 'c-linklist-footer': true }}
          />
        ) : (
          <Footer
            {...footerProps}
            key="footer"
            ShowLogo={isMediumViewport()}
            FooterLogo={FooterLogo}
            siteFooterLinkListModifiers={{
              'c-linklist--no-icons': true,
            }}
            Modifiers={{ 'footer-linklist': true }}
          />
        )}

        {!props.CookiePro.Enabled && <CookiePanel {...props} />}
      </div>
    </>
  );
};

export default Render.Root(Layout);
