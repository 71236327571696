import React from 'react';

const Logo = ({ link, logosr, width, height, fill, viewBoxWidth, viewBoxHeight }) => {
  const Svg = (
    <svg
      width={width}
      height={height}
      className="vardpersonal-logo__svg"
      viewBox={`0 0 ${viewBoxWidth ?? width} ${viewBoxHeight ?? height}`}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8.33298 30C6.39358 30 4.81783 28.4545 4.81783 26.5152L4.84813 7.57576H4.21177C2.09055 7.57576 0.363281 5.90909 0.363281 3.78788C0.363281 1.72727 2.06025 0 4.18146 0H8.33298C10.2724 0 11.8481 1.57576 11.8481 3.48485V26.5152C11.8481 28.4242 10.2724 30 8.33298 30Z" />
      <path d="M21.3026 26.5152V7.63636L20.6966 7.57576C18.5754 7.57576 16.8481 5.90909 16.8481 3.78788C16.8481 1.72727 18.5451 0 20.6663 0H24.8178C26.7572 0 28.3329 1.57576 28.3329 3.48485V26.5152C28.3329 28.4242 26.7572 30 24.8178 30C22.8784 30 21.3026 28.4545 21.3026 26.5152Z" />
      <path d="M60.3935 7.63636H66.0299L69.1208 0H60.3935C58.2723 0 56.5753 1.72727 56.5753 3.78788C56.545 5.90909 58.2723 7.63636 60.3935 7.63636Z" />
      <path d="M70.8481 2.24242C71.5753 0.424242 73.6057 -0.454545 75.4238 0.272727C77.2117 0.969697 78.0905 3.0303 77.3935 4.78788L68.1208 27.7576C67.3935 29.5758 65.3632 30.4545 63.545 29.7273C61.7269 29 60.8481 27 61.5753 25.1818L70.8481 2.24242Z" />
      <path d="M36.1814 7.63636H41.8178L44.9087 0H36.1814C34.0602 0 32.3633 1.72727 32.3633 3.78788C32.333 5.90909 34.0602 7.63636 36.1814 7.63636Z" />
      <path d="M46.636 2.24242C47.3633 0.424242 49.3936 -0.454545 51.2117 0.272727C52.9996 0.969697 53.8784 3.0303 53.1814 4.78788L43.9087 27.7576C43.1814 29.5758 41.1511 30.4545 39.333 29.7273C37.5148 29 36.636 27 37.3633 25.1818L46.636 2.24242Z" />
    </svg>
  );
  return link ? (
    <a href={link} className="vardpersonal-logo__link" aria-label={logosr}>
      {Svg}
    </a>
  ) : (
    <>
      <span className="show-for-sr" data-cy="ui-header-logosr">
        {logosr}
      </span>
      {Svg}
    </>
  );
};

export default Logo;
