import classNames from 'classnames';
import React from 'react';
import CollapsiblePanel from '../../platformui/CollapsiblePanel';
import { useSweLang } from '../../platformui/Helpers';
import Linklist from '../../platformui/Linklist';
import Logo from '../logo-vardpersonal';

const Footer = ({
  SiteFooter,
  Language,
  Modifiers,
  Content,
  SiteLogoSr,
  siteFooterLinkListModifiers,
}) => {
  if (!SiteFooter) return false;

  const linkLists = [
    SiteFooter.LinkListFirst,
    SiteFooter.LinkListSecond,
    SiteFooter.LinkListThird,
  ].filter((list) => list && list.Links);

  if (SiteFooter.CookieSettingsLinkEnabled && linkLists.length > 0) {
    const lastList = linkLists[linkLists.length - 1];
    const isCookieSettingsPresent = lastList?.Links?.some(
      (link) => link.id === 'ot-container ot-sdk-btn'
    );

    if (!isCookieSettingsPresent) {
      lastList.Links.push({
        Text: 'Inställningar för kakor',
        id: 'ot-container ot-sdk-btn',
        className: 'ot-sdk-show-settings',
      });
    }
  }

  const columnClasses = classNames({
    columns: true,
    'medium-10': true,
    'large-6': linkLists.length < 3,
    'large-4': linkLists.length === 3,
  });

  return (
    <footer
      className="c-footer vardpersonal-footer"
      lang={useSweLang(Language)}
    >
      <div className="vardpersonal-footer__content">
        <div className="row">
          <div className="show-for-large">
            {linkLists.map((list, index) => (
              <div key={index} className={columnClasses}>
                <Linklist
                  Modifiers={Modifiers}
                  {...list}
                  Heading={list.Heading || null}
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="vardpersonal-footer__collapsible-lists hide-for-large">
        {linkLists.map((list, index) => (
          <CollapsiblePanel
            key={index}
            Id={`panel${index + 1}`}
            className="c-collapsible-panel"
            Heading={list.Heading || null}
            Language={list.HeadingLanguage}
            ExpandBtnText={Content.Translations?.expandbuttontext}
          >
            <Linklist
              Modifiers={{
                'c-linklist--collapsible': true,
                'c-linklist--no-icons': true,
              }}
              {...list}
              Heading={null}
            />
          </CollapsiblePanel>
        ))}
      </div>

      <div className="vardpersonal-footer__site">
        <div className="row">
          <div className="vardpersonal-footer__site__inner">
            <div className="vardpersonal-footer__logo">
              <Logo
                logosr={SiteLogoSr}
                link={SiteFooter.LogotypeLinkUrl}
                width="62"
                height="24"
                viewBoxWidth="80"
                viewBoxHeight="30"
                fill="white"
              />
            </div>
            <h1
              className="vardpersonal-footer__heading"
              aria-label={`${SiteFooter.Heading} ${SiteFooter.SubHeading}`.trim()}
            >
              {SiteFooter.Heading}
            </h1>
            {SiteFooter.SubHeading && (
              <h2
                aria-hidden={true}
                className="vardpersonal-footer__subheading"
              >
                – {SiteFooter.SubHeading}
              </h2>
            )}
            <div
              className="vardpersonal-footer__site__inner__editor"
              dangerouslySetInnerHTML={{
                __html: SiteFooter.MainContent,
              }}
            />
            {SiteFooter.SiteInformation && (
              <div
                className="vardpersonal-footer__site__inner--with-siteinformation"
                dangerouslySetInnerHTML={{
                  __html: SiteFooter.SiteInformation,
                }}
              ></div>
            )}
            <div className="vardpersonal-footer__site__inner__bottom-links c-linklist c-linklist--site-footer">
              <Linklist
                {...SiteFooter}
                Modifiers={{
                  ...siteFooterLinkListModifiers,
                }}
                Heading={null}
              />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
