import React from 'react';

export default () => (
  <svg
    className="region-logo__img region-logo__img--is-visible"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMid meet"
    width="31"
    height="34"
    viewBox="0 0 31 34"
  >
    <defs>
      <path id="a1" d="M.016.14h4.917v6.556H.016z" />
      <path id="c1" d="M.062.02h4.832V6.41H.062z" />
      <path id="e1" d="M0 27.631h29.652V0H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h31v34H0z" />
      <g transform="translate(0 3)">
        <path
          fill="#353535"
          d="M13.074 7.929a8.576 8.576 0 0 1 1.625-.518L14.673 0a9.99 9.99 0 0 0-5.177 1.524l3.279 6.544c.098-.047.195-.094.299-.14M12.027 8.477l-4.22-5.626a10.19 10.19 0 0 0-2.79 4.854l5.626 2.173c.277-.542.753-1.002 1.384-1.4M19.132 8.572c1.276 2.636-1.658 2.571-.446 4.628.563.956 2.337 2.203 4.075 3.264a10.187 10.187 0 0 0 2.158-6.285 10.19 10.19 0 0 0-1.67-5.607l-4.394 3.591c.107.125.204.257.277.409M18.21 7.654l3.65-4.771a10.051 10.051 0 0 0-4.926-2.66l-1.397 7.054c1.018-.105 1.974.006 2.673.377M14.04 19.406a1.101 1.101 0 0 1-1.096-1.106c0-.61.491-1.105 1.097-1.105.605 0 1.096.495 1.096 1.105 0 .611-.49 1.106-1.096 1.106m2.668-3.377c-2.04-1.864-4.528-.514-5.804-3.022-.453-.89-.604-1.655-.512-2.32L4.72 9.982c-.001.065-.005.13-.005.196 0 5.623 4.523 10.182 10.102 10.182 1.546 0 3.01-.35 4.32-.977-.67-1.212-1.524-2.53-2.428-3.355"
        />
        <g transform="translate(0 20.936)">
          <mask id="b1" fill="#fff">
            <use xlinkHref="#a1" />
          </mask>
          <path
            fill="#353535"
            d="M4.641 1.881c-.932-.412-1.488-.57-2.034-.57-.575 0-.943.205-.943.524 0 .964 3.27.693 3.27 2.922 0 1.227-1.037 1.939-2.469 1.939-1.12 0-1.677-.291-2.317-.619V4.701c.923.609 1.488.824 2.195.824.612 0 .942-.215.942-.59 0-1.05-3.269-.665-3.269-2.96C.016.87.977.14 2.466.14 3.18.14 3.83.29 4.64.636V1.88z"
            mask="url(#b1)"
          />
        </g>
        <path
          fill="#353535"
          d="M8.993 24.156l2.836 3.391H9.822L7.28 24.55v2.997H5.677v-6.388H7.28v2.895h.019l2.025-2.895h1.761zM23.778 27.547h-1.347l-2.83-4.093v4.093h-1.6V21.16h1.469l2.707 3.906V21.16h1.601z"
        />
        <g transform="translate(24.758 21.139)">
          <mask id="d1" fill="#fff">
            <use xlinkHref="#c1" />
          </mask>
          <path
            fill="#353535"
            d="M4.894 6.409H.062V.02h4.766v1.17H1.663v1.34h3.023v1.17H1.663v1.537h3.23z"
            mask="url(#d1)"
          />
        </g>
        <path
          fill="#353535"
          d="M.31 19.635h.163c.086 0 .17-.043.17-.17 0-.11-.06-.167-.177-.167H.31v.337zm.246-.609c.254 0 .405.205.405.427 0 .162-.07.284-.212.363.127.076.165.259.34.694H.75c-.133-.318-.183-.603-.35-.603h-.09v.603H0v-1.484h.556zM2.172 20.51h-.917v-1.484h.904v.272h-.594v.311h.567v.272h-.567v.357h.607zM3.557 19.453a.92.92 0 0 0-.464-.138c-.337 0-.455.248-.455.468 0 .263.173.437.43.437a.392.392 0 0 0 .205-.052v-.174h-.18v-.272h.49v.607c-.093.115-.322.2-.523.2-.433 0-.74-.315-.74-.759 0-.452.305-.763.748-.763.185 0 .366.05.49.121v.325z"
        />
        <mask id="f1" fill="#fff">
          <use xlinkHref="#e1" />
        </mask>
        <path
          fill="#353535"
          d="M3.876 20.51h.31v-1.484h-.31zM5.122 19.315c-.237 0-.393.181-.393.453s.156.452.393.452c.235 0 .392-.18.392-.452s-.157-.453-.392-.453m0 1.214c-.455 0-.712-.383-.712-.761 0-.359.244-.761.712-.761.468 0 .71.404.71.76 0 .382-.264.762-.71.762M7.288 20.51h-.26l-.687-.951v.95h-.31v-1.483h.285l.663.907v-.907h.31zM14.041 22.685l-.942 2.482h1.978l-1.036-2.482zm3.76 4.862h-1.725l-.527-1.265h-2.874l-.48 1.265h-1.697l2.591-6.39h1.856l2.856 6.39z"
          mask="url(#f1)"
        />
      </g>
    </g>
  </svg>
);
