const StartPage = async () =>
  await import('./start').then((page) => page.default);
const ArticlePage = async () =>
  await import('./article').then((page) => page.default);
const SectionStartPage = async () =>
  await import('./sectionstart').then((page) => page.default);
const PublicationPage = async () =>
  await import('./publication').then((page) => page.default);
const PwtPublicationPage = async () =>
  await import('./publication/pwt-publication').then((page) => page.default);
const CollectionPage = async () =>
  await import('./collection').then((page) => page.default);
const SearchPage = async () =>
  await import('./search').then((page) => page.default);
const SubjectGroupPage = async () =>
  await import('./subjectgroup').then((page) => page.default);
const SubjectGroupOverviewPage = async () =>
  await import('./subjectgroupoverview').then((page) => page.default);
const CourseOfCarePage = async () =>
  await import('./courseofcare').then((page) => page.default);
const ErrorPage = async () =>
  await import('./error').then((page) => page.default);

export const importPage = (pageTypeName) => {
  switch (pageTypeName) {
    case 'ArticlePage':
      return ArticlePage();
    case 'StartPage':
      return StartPage();
    case 'SectionStartPage':
      return SectionStartPage();
    case 'PublicationPage':
      return PublicationPage();
    case 'PwtPublicationPage':
      return PwtPublicationPage();
    case 'CollectionPage':
      return CollectionPage();
    case 'SearchPage':
      return SearchPage();
    case 'SubjectGroupPage':
      return SubjectGroupPage();
    case 'SubjectGroupOverviewPage':
      return SubjectGroupOverviewPage();
    case 'CourseOfCarePage':
      return CourseOfCarePage();
    case 'ErrorPage':
      return ErrorPage();
    default:
      return StartPage();
  }
};
