import React from 'react';
import classNames from 'classnames';
import Burger from '../header-vardpersonal/burger';
import RegionLogo from '../header-vardpersonal/region-logo';

const HeaderTools = (props) => {

  return (
    <>
      <div
        className={classNames({
          'c-header--is-navigation': props.mobileNavVis,
          'c-header--is-animating': props.animationStart,
        })}
      ></div>
      <div className="header-tools">
        <ul className="header-tools__list">
          {props.switchToResidentUrl && props.switchToResidentText  && (
          <li className="header-tools__list__item header-tools__list__item--switch-to-resident">
            <a
              aria-controls="switch-to-resident"
              className="header-tools__list__item__button"
              data-cy="header-tools-switch"
              href={props.switchToResidentUrl}
            >
              <span className="switch-icon__container">
                <i className="switch-icon" />
              </span>
              <span>{props.switchToResidentText}</span>
              <span className="show-for-sr">
                {props.Content.Translations.quicklinkssr}
              </span>
            </a>
          </li>
          )}
          <li className="header-tools__list__item header-tools__list__item--mobile">
            <RegionLogo {...props} />
          </li>
          <li
            className={classNames({
              'header-tools__list__item header-tools__list__item--search': true,
              'header-tools__list__item--is-expanded':
                props.activeTool === 'search',
            })}
          >
            {!props.checkViewboxIsMobile && (
              <button
                type="button"
                aria-controls="panel-globalsearch"
                className="header-tools__list__item__button header-tools__list__item__button--search-desktop"
                data-cy="header-tools-search-btn"
                onClick={() => {
                  props.toggle('search');
                }}
                aria-expanded={props.activeTool === 'search'}
              >
                <span
                  className={
                    props.activeTool === 'search'
                      ? 'search-icon__container search-icon__container--close'
                      : 'search-icon search-icon__container--open'
                  }
                >
                  <i
                    className={
                      props.activeTool === 'search'
                        ? 'search-icon search-icon--close'
                        : 'search-icon search-icon--open'
                    }
                  />
                </span>
                <span>{props.Content.Translations.search}</span>
                <span className="show-for-sr">
                  {props.Content.Translations.quicklinkssr}
                </span>
              </button>
            )}
          </li>
          <li className="header-tools__list__item header-tools__list__item--burger">
            <Burger
              {...props}
              showHeader={true}
              handleHamburgerClick={props.handleHamburgerClick}
              burgerBtnClassname="header-tools__burger"
            />
          </li>
        </ul>
      </div>
    </>
  );
};

export default HeaderTools;
