import React from 'react';

export default () => (
  <svg
    className="region-logo__img region-logo__img--is-visible"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMinYMid meet" 
    width="220"
    height="60"
    viewBox="0 0 808 144.4631"
  >
  <defs>
    <style>{` .cls-1 { fill: #353535; stroke-width: 0px; } `}</style>
  </defs>
<g id="VÄSTRA_GÖTALANDSREGIONEN">
	<g id="GÖTALANDSREGIONEN">
		<path className='cls-1' d="M180.9559,108.6262c0-14.9268,6.9893-21.5615,16.9404-21.5615c8.708,0,15.1055,4.6201,15.8154,14.5723h-7.2266
			c-0.5918-5.5684-3.7314-8.1152-8.5889-8.1152c-5.8643,0-9.6553,3.9092-9.6553,15.2227c0,11.6699,3.5547,15.2236,9.9512,15.2236
			c5.0947,0,8.293-2.7246,8.5303-9.0625H197.66v-6.2197h15.9336v20.6729h-6.8711v-8.6484c-1.125,6.2783-5.3906,9.418-10.8398,9.418
			C186.9969,130.1281,180.9559,123.8498,180.9559,108.6262z"/>
		<path className='cls-1' d="M221.2665,108.6262c0-14.5713,6.8711-21.5615,17.3555-21.5615c10.4258,0,17.2969,6.9902,17.2969,21.5615
			s-6.8711,21.502-17.2969,21.502C228.1376,130.1281,221.2665,123.1974,221.2665,108.6262z M238.6219,123.9084
			c6.1016,0,10.0107-3.9092,10.0107-15.1641c0-11.3135-3.9092-15.2227-10.0107-15.2227c-6.1006,0-10.0693,3.9092-10.0693,15.2227
			C228.5526,119.9992,232.5214,123.9084,238.6219,123.9084z M232.6991,77.4689c2.1318,0,3.8506,1.6582,3.8506,3.791
			c0,2.0732-1.7188,3.791-3.8506,3.791c-2.1914,0-3.9092-1.7178-3.9092-3.791C228.7899,79.1271,230.5077,77.4689,232.6991,77.4689z
			 M244.5458,77.4689c2.1328,0,3.8506,1.6582,3.8506,3.791c0,2.0732-1.7178,3.791-3.8506,3.791
			c-2.1914,0-3.9092-1.7178-3.9092-3.791C240.6366,79.1271,242.3544,77.4689,244.5458,77.4689z"/>
		<path className='cls-1' d="M270.4276,94.2912h-10.248v-6.3975h27.4854v6.3975h-10.248v35.0674h-6.9893V94.2912H270.4276z"/>
		<path className='cls-1' d="M314.2577,117.8078h-14.6309l-3.1396,11.5508h-7.0488l11.6104-41.4648h11.9648l11.6104,41.4648h-7.2266L314.2577,117.8078
			z M312.5399,111.5881l-5.5684-20.5547l-5.627,20.5547H312.5399z"/>
		<path className='cls-1' d="M331.8475,87.8937h6.9893v35.127h15.875v6.3379h-22.8643V87.8937z"/>
		<path className='cls-1' d="M384.9774,117.8078h-14.6309l-3.1396,11.5508h-7.0488l11.6104-41.4648h11.9648l11.6104,41.4648h-7.2266L384.9774,117.8078
			z M383.2596,111.5881l-5.5684-20.5547l-5.627,20.5547H383.2596z"/>
		<path className='cls-1' d="M409.3192,91.9816v37.377h-6.6338V87.8937h11.7285l14.0381,36.252v-36.252h6.6348v41.4648h-11.1953L409.3192,91.9816z"/>
		<path className='cls-1' d="M445.9813,87.8937h14.335c10.5439,0,17.5928,6.2197,17.5928,20.7324c0,14.1572-7.7012,20.7324-18.0078,20.7324h-13.9199
			L445.9813,87.8937L445.9813,87.8937z M459.5458,123.257c6.9902,0,11.0771-3.791,11.0771-14.5127
			c0-10.6025-3.9092-14.4531-10.7812-14.4531h-6.8711v28.9658C452.9706,123.257,459.5458,123.257,459.5458,123.257z"/>
		<path className='cls-1' d="M484.4794,117.926h7.167c0.5928,3.9092,3.376,6.2793,8.5303,6.2793c4.7383,0,7.3447-2.6064,7.3447-5.7461
			c0-3.2578-1.7178-4.7393-6.1602-5.9238l-6.457-1.7764c-5.2715-1.4814-9.7139-4.5615-9.7139-11.3145
			c0-6.9297,5.3311-12.3799,14.1562-12.3799c8.8262,0,14.2764,5.0352,14.8682,12.9727h-7.1084
			c-0.4736-4.3242-3.1387-6.8125-7.8779-6.8125c-4.6797,0-6.8711,2.7842-6.8711,5.7461c0,3.376,2.0732,4.917,6.8115,6.042
			l5.5684,1.4219c6.3975,1.5986,9.9512,5.1533,9.9512,11.2539c0,6.9902-5.627,12.4395-14.6309,12.4395
			C491.0546,130.1281,485.0126,125.6857,484.4794,117.926z"/>
		<path className='cls-1' d="M523.8661,87.8937h15.2822c8.0557,0,12.6768,4.3838,12.6768,12.084c0,6.8125-3.9102,11.1953-11.0176,11.6699
			l11.0176,17.7109h-7.582l-13.3877-21.7393v21.7393h-6.9893L523.8661,87.8937L523.8661,87.8937z M537.9637,107.2043
			c4.3838,0,6.8711-2.0732,6.8711-6.5752c0-4.7383-2.4873-6.5156-6.8711-6.5156h-7.1084v13.0908
			C530.8553,107.2043,537.9637,107.2043,537.9637,107.2043z"/>
		<path className='cls-1' d="M561.1805,87.8937h24.8789v6.3975h-17.8896v10.8398h17.2969v6.2197h-17.2969v11.7881h18.126v6.2197h-25.1152V87.8937
			H561.1805z"/>
		<path className='cls-1' d="M593.6376,108.6262c0-14.9268,6.9893-21.5615,16.9404-21.5615c8.708,0,15.1055,4.6201,15.8154,14.5723h-7.2266
			c-0.5918-5.5684-3.7314-8.1152-8.5889-8.1152c-5.8643,0-9.6553,3.9092-9.6553,15.2227c0,11.6699,3.5547,15.2236,9.9512,15.2236
			c5.0947,0,8.293-2.7246,8.5303-9.0625h-9.0625v-6.2197h15.9336v20.6729h-6.8711v-8.6484c-1.125,6.2783-5.3906,9.418-10.8398,9.418
			C599.6786,130.1281,593.6376,123.8498,593.6376,108.6262z"/>
		<path className='cls-1' d="M636.8749,87.8937h6.9893v41.4648h-6.9893V87.8937z"/>
		<path className='cls-1' d="M652.9843,108.6262c0-14.5713,6.8711-21.5615,17.3564-21.5615c10.4248,0,17.2959,6.9902,17.2959,21.5615
			s-6.8711,21.502-17.2959,21.502C659.8553,130.1281,652.9843,123.1974,652.9843,108.6262z M670.3407,123.9084
			c6.1006,0,10.0098-3.9092,10.0098-15.1641c0-11.3135-3.9092-15.2227-10.0098-15.2227c-6.1016,0-10.0703,3.9092-10.0703,15.2227
			C660.2704,119.9992,664.2391,123.9084,670.3407,123.9084z"/>
		<path className='cls-1' d="M703.2343,91.9816v37.377h-6.6338V87.8937h11.7285l14.0381,36.252v-36.252h6.6348v41.4648h-11.1953L703.2343,91.9816z"/>
		<path className='cls-1' d="M740.0741,87.8937h24.8779v6.3975h-17.8887v10.8398h17.2969v6.2197h-17.2969v11.7881h18.126v6.2197h-25.1152
			L740.0741,87.8937L740.0741,87.8937z"/>
		<path className='cls-1' d="M781.5936,91.9816v37.377h-6.6338V87.8937h11.7285l14.0381,36.252v-36.252h6.6348v41.4648H796.166L781.5936,91.9816z"/>
	</g>
	<g id="VÄSTRA">
		<path className='cls-1' d="M181.3661,25.7429h7.3457l10.0107,37.6733l10.0098-37.6733h7.1084l-11.373,41.4644h-11.7285L181.3661,25.7429z"/>
		<path className='cls-1' d="M241.8885,55.6564h-14.6309l-3.1396,11.5508h-7.0488l11.6104-41.4644h11.9648l11.6104,41.4644h-7.2266L241.8885,55.6564z
			 M228.7977,15.3176c2.1328,0,3.8506,1.6587,3.8506,3.791c0,2.0732-1.7178,3.791-3.8506,3.791
			c-2.1914,0-3.9092-1.7178-3.9092-3.791C224.8885,16.9763,226.6063,15.3176,228.7977,15.3176z M240.1708,49.4367l-5.5684-20.5542
			l-5.627,20.5542H240.1708z M240.6444,15.3176c2.1328,0,3.8506,1.6587,3.8506,3.791c0,2.0732-1.7178,3.791-3.8506,3.791
			c-2.1914,0-3.9092-1.7178-3.9092-3.791C236.7352,16.9763,238.453,15.3176,240.6444,15.3176z"/>
		<path className='cls-1' d="M256.6346,55.7751h7.167c0.5928,3.9092,3.376,6.2788,8.5303,6.2788c4.7383,0,7.3447-2.6064,7.3447-5.7456
			c0-3.2583-1.7178-4.7393-6.1602-5.9238l-6.457-1.7769c-5.2715-1.481-9.7139-4.561-9.7139-11.314
			c0-6.9302,5.3311-12.3799,14.1562-12.3799c8.8262,0,14.2764,5.0347,14.8682,12.9722h-7.1084
			c-0.4736-4.3237-3.1387-6.812-7.8779-6.812c-4.6797,0-6.8711,2.7842-6.8711,5.7461c0,3.376,2.0732,4.9165,6.8115,6.042
			l5.5684,1.4214c6.3975,1.5991,9.9512,5.1533,9.9512,11.2544c0,6.9897-5.627,12.4395-14.6309,12.4395
			C263.2098,67.9772,257.1678,63.5349,256.6346,55.7751z"/>
		<path className='cls-1' d="M301.7079,32.1403h-10.248v-6.3975h27.4854v6.3975h-10.248v35.0669h-6.9893V32.1403H301.7079z"/>
		<path className='cls-1' d="M326.4061,25.7429h15.2822c8.0557,0,12.6768,4.3833,12.6768,12.084c0,6.812-3.9102,11.1953-11.0176,11.6694
			l11.0176,17.7109h-7.582L333.3954,45.468v21.7393h-6.9893V25.7429z M340.5038,45.0534c4.3838,0,6.8711-2.0732,6.8711-6.5752
			c0-4.7388-2.4873-6.5156-6.8711-6.5156h-7.1084v13.0908H340.5038z"/>
		<path className='cls-1' d="M384.9852,55.6564h-14.6309l-3.1396,11.5508h-7.0488l11.6104-41.4644h11.9648l11.6104,41.4644h-7.2266L384.9852,55.6564z
			 M383.2675,49.4367l-5.5684-20.5542l-5.627,20.5542H383.2675z"/>
	</g>
</g>
<g id="SYMBOL">
	<g id="SYMBOL_00000045618139341792585810000009867492219756089512_">
		<path id="Bana_3" className='cls-1' d="M88.4953,99.3867c-13.2015,1.3151-27.0267-3.0734-36.989-10.936l32.2957,55.4548
			c5.2589-9.0304,27.5073-47.2332,32.2952-55.4548C108.3582,94.5218,98.8302,98.4666,88.4953,99.3867z"/>
		<path id="Bana_2" className='cls-1' d="M167.609,0l-49.275,0.0001c-7.1679,0.1405-17.041-0.3969-24.2325,0.952
			C79.1428,3.704,62.8351,12.8306,57.2802,32.3307c-1.4624,5.4561-1.6622,11.6327-0.0887,17.1392
			c4.1755,16.4984,22.2795,27.5076,37.678,18.829c6.9465-3.9763,11.3172-11.9695,10.6797-19.9794
			c-0.5545-11.0891-10.7206-20.2448-21.7564-20.0407c5.9304-5.0486,14.1964-7.3214,21.8865-6.071
			c0.0001,0.0001,0.0001,0.0002,0.0002,0.0002c17.2033,2.7695,28.8366,22.1179,26.0174,39.4536L167.609,0z"/>
		<path id="Bana_1" className='cls-1' d="M72.8482,0.0001H0l35.9083,61.6612c-0.5586-3.119-0.884-6.3408-0.8752-9.6206
			C35.1025,26.347,52.742,7.6638,72.8482,0.0001z"/>
	</g>
</g>
  </svg>
);
