import React, { useState } from 'react';
import { isExpanded } from './helpers';

export default (WrapperComponent) => {
  const HeaderContainer = (props) => {
    const [activeTool, setActiveTool] = useState(
      isExpanded(props) ? 'region-picker' : ''
    );
    const getRegions = null;

    let timer = null;

    const toggle = (id) => {
      setActiveTool(id === activeTool ? '' : id);

      if (id === 'search') {
        if (typeof window === 'undefined') {
          setFocus('globalsearch');
          return;
        }
        const mq = window.matchMedia('(min-width: 1025px)');
        mq.matches
          ? setFocus('globalsearch')
          : setFocus('globalsearch--mobile');
      }
    };

    const setFocus = (id) => {
      clearTimeout(timer);
      timer = setTimeout(() => document.getElementById(id).focus(), 420);
    };

    return (
      <WrapperComponent
        {...props}
        toggle={(e) => toggle(e)}
        activeTool={activeTool}
        getRegions={getRegions}
      />
    );
  };
  return HeaderContainer;
};
